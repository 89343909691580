import { AxiosInstance } from 'axios'
import {
  EventGroupResponse,
  EventQuery,
  EventTypeQuery,
  EventTypeResponse,
  OccurrenceEvent,
  EventGroupQuery,
} from 'services/event/types'
import { Result } from 'services/types'
import HttpClient from '../httpClient'

import { endpoints } from './endpoints'

import { useQuery } from '@tanstack/react-query'
import buildUrl from 'utilities/buildUrl'

export interface EventDriver {
  queryEvents(query: EventQuery): Promise<Result<OccurrenceEvent>>
  queryEventTypes(query: EventTypeQuery): Promise<Result<EventTypeResponse>>
  queryEventGroups(query: EventGroupQuery): Promise<Result<EventGroupResponse>>
}

class EventDriverImpl implements EventDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async queryEventGroups(
    query: EventTypeQuery,
  ): Promise<Result<EventGroupResponse>> {
    const response = await this.httpClient.get<Result<EventGroupResponse>>(
      '/eventGroup',
      { params: query },
    )

    return response.data
  }

  public async queryEvents(
    query: EventQuery,
  ): Promise<Result<OccurrenceEvent>> {
    const response = await this.httpClient.get<Result<OccurrenceEvent>>(
      '/event',
      { params: query },
    )

    return response.data
  }

  public async queryEventTypes(
    query?: EventTypeQuery,
  ): Promise<Result<EventTypeResponse>> {
    const response = await this.httpClient.get<Result<EventTypeResponse>>(
      '/eventType',
      { params: query },
    )

    return response.data
  }
}

export default new EventDriverImpl()

export * from './useQueryEvent'
export * from './types'

export const useQueryEventGroup = (query?: EventGroupQuery) => {
  return useQuery({
    queryKey: ['query-event-group', query],
    queryFn: async () => {
      const response = await HttpClient.get<
        Result<{ id: string; description: string }>
      >(
        buildUrl({
          route: endpoints.eventGroup,
          queryParams: query,
        }),
      )

      return response.data
    },
  })
}
